import LinkedText from "../../display/LinkedText";
import { useState, useEffect, useContext } from "react";
import { NavLink, useParams, useNavigate} from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import ErrorPage from "../../display/ErrorPage";


function CardTipPage() {

    const { account } = useContext(AuthContext)

    const { card_number } = useParams()
    const [cardTip, setCardTip] = useState({
        card_name: "",
        card_number: 0,
        content: "",
        links: ""
    })
    const [noCardTip, setNoCardTip] = useState(false)

    const getCardTip = async() =>{
        const cardTipResponse = await fetch(`https://pm-deck-react-only.onrender.com/card_tips/${card_number}`)
        // const cardResponse = await fetch(`http://localhost:4000/cards/${card_number}`)
        if (cardTipResponse.ok) {
            const cardTipData = await cardTipResponse.json()
            setCardTip(cardTipData);
        } else {
            setNoCardTip(true)
        }
    };

    useEffect(() => {
        window.scroll(0, 0);
        getCardTip();
    }, [card_number]);

    useEffect(() => {
        document.title = `Card Tips: ${cardTip.card_name} - PM CardBase`
        return () => {
            document.title = "PlayMaker CardBase"
        };
    }, [cardTip])


    return (
        <>
            {!noCardTip?
                <div className="white-space content flex-column-split">
                    <span>
                        <div className="flex-content margin-bottom-20">
                            <h1 className="margin-top-10 ellipsis">Card Tips: {cardTip.card_name}</h1>
                            { account && account.roles.includes("admin")?
                                <NavLink className="nav-link" to={`/cardtips/${card_number}/edit`}>
                                    <h5>[Edit]</h5>
                                </NavLink>
                            :null}
                        </div>
                        <LinkedText
                            content={cardTip.content}
                            links={cardTip.links}
                        />
                    </span>
                    <span>
                        <NavLink className="nav-link no-pad" to={`/cards/${card_number}`}>
                            <button
                                style={{ width: "100%", marginTop: "20px"}}>
                                Back to {cardTip.card_name}
                            </button>
                        </NavLink>
                        { account && account.roles.includes("admin")?
                            <NavLink className="nav-link no-pad" to={"/cardtips"}>
                                <button
                                    style={{ width: "100%", marginTop: "20px"}}>
                                    Back to Card Tips List
                                </button>
                            </NavLink>
                        :null}
                    </span>
                </div>
            :<ErrorPage path={"/cards"}/>}
        </>
    );
}

export default CardTipPage;
