import {
    Card,
} from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { DeckQueryContext } from "../context/DeckQueryContext";
import { AuthContext } from "../context/AuthContext";
import deckQueries from "../QueryObjects/DeckQueries";


function AccountDecks(props) {
    const [decks, setDecks] = useState([]);
    const [noDecks, setNoDecks] = useState(false)
    const {
        account,
        users,
        getAccountData,
    } = useContext(AuthContext)

    // const account = {
    //     "userData": {
    //         "uid": "DGVcOcdyaYPod2t5Zvs5ROwmWaY2",
    //         "email": "nantahkl@gmail.com",
    //         "emailVerified": true,
    //         "displayName": "Kornebari Nantah",
    //         "isAnonymous": false,
    //         "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLOW4_R7XSuovw5HuRkxBhfubbOlrURfCWNTorZQDOZ2cbWLA=s96-c",
    //         "providerData": [
    //             {
    //                 "providerId": "google.com",
    //                 "uid": "113387828856354681577",
    //                 "displayName": "Kornebari Nantah",
    //                 "email": "nantahkl@gmail.com",
    //                 "phoneNumber": null,
    //                 "photoURL": "https://lh3.googleusercontent.com/a/ACg8ocLOW4_R7XSuovw5HuRkxBhfubbOlrURfCWNTorZQDOZ2cbWLA=s96-c"
    //             }
    //         ],
    //         "stsTokenManager": {
    //             "refreshToken": "AMf-vBwaOY8pFxIET_kD6lfovwZzf1Mu4CENtGv-FBOcyI6Pv4rMKgubvkDy-1nEKvZH0dHvA7F2Kv8MDJO2cT0-Qps-Jxw_hyB87glfh50DZD7hiFHdZ-c-UweZpPlWJTBBgO8E9Y93pzLNLg0JYZ8E_fGxIT72IWdUyqsnAXqztzvDsjZlDCPLRoXrDIqc9wm41JmHQcGKJpttrlg9xpu5CiIF_2Qa6i7awtRL-rC9KY0ulB-yRlcCir0wYog_KEIC_i5X6KmFclUAij3-7MeLMYTnXzJBnNUMzlD0iCNNu-dZHX6qtsixAX19b-G1YUcjmGusRa38VWCpRUCVrvOBvEgfLBRRoaQdw4cO2kVoogb2SqpFR_mwxQE-eDhcmP0j-hIu_ZLaKUhQqO-jEwI62j69f-3h8fmA9srW_6jneb3jMfe92xs",
    //             "accessToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjFkYmUwNmI1ZDdjMmE3YzA0NDU2MzA2MWZmMGZlYTM3NzQwYjg2YmMiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiS29ybmViYXJpIE5hbnRhaCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NMT1c0X1I3WFN1b3Z3NUh1Umt4QmhmdWJiT2xyVVJmQ1dOVG9yWlFET1oyY2JXTEE9czk2LWMiLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vY2FyZGJhc2UtMWIxYTciLCJhdWQiOiJjYXJkYmFzZS0xYjFhNyIsImF1dGhfdGltZSI6MTcyMjg3NzEzMCwidXNlcl9pZCI6IkRHVmNPY2R5YVlQb2QydDVadnM1Uk93bVdhWTIiLCJzdWIiOiJER1ZjT2NkeWFZUG9kMnQ1WnZzNVJPd21XYVkyIiwiaWF0IjoxNzIyODc3MTMwLCJleHAiOjE3MjI4ODA3MzAsImVtYWlsIjoibmFudGFoa2xAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZ29vZ2xlLmNvbSI6WyIxMTMzODc4Mjg4NTYzNTQ2ODE1NzciXSwiZW1haWwiOlsibmFudGFoa2xAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoiZ29vZ2xlLmNvbSJ9fQ.v2z43kN4PSqQaNSF85ZC_tXA8OOvtW8Py8cURISPyB-W-O9UUIYp0UYNbSzGGu2QMIvN2a5-TgmlLCCks2m0Qlcjj32YffZqjikpqo-OI5GBiD_wAIniukGzFv8rh4scTv4f-OcSbvspJflmHK4amxQ2GGC2-hEABuuHBD2zS_RoZcek1WOOxjDqvDIUle0T5TKLie343jYN4q5va0ixezF36RkGMYOoa7_I41nCrn5A9j8C2bclkQK0Uge1TXi21KGFQSzdONe0imFh9XVwuxAnPZ1wsazMRmVppHy1uVlXhDtPWviXliCOlqdOFjLOomnxLLViMwpif-AIGsy7_g",
    //             "expirationTime": 1722880730047
    //         },
    //         "createdAt": "1714178117633",
    //         "lastLoginAt": "1722401164688",
    //         "apiKey": "AIzaSyDgkedttmEsg5xpChub6FDbr5AVhs9NEJM",
    //         "appName": "[DEFAULT]"
    //     },
    //     "username": "TeamPlayMaker",
    //     "collection": [],
    //     "wishlist": [],
    //     "decks": [],
    //     "favorited_decks": [
    //         "64fc6447eff176ba42293519",
    //         "6567d2ce6b97532b76bf65b7",
    //         "65712e5b0bb745cd65277818",
    //         "6571e9e30bb745cd65277829",
    //         "6551865dd449cdc3d0b3f93c",
    //         "65ec8e93f28d4a1e36ea0ad5",
    //         "658747711df6bfa47c978bbd",
    //         "js3OEaf4GczSY4xepsBbK5wp",
    //         "6563553fa88a701900626aee",
    //         "B0xE2Cw5GZA4nPf1JfL2s26o",
    //         "9pSUGncCWNJVkJtSrCLirJ5E",
    //         "UB99lNIJkpUO8GinVngNpqLN",
    //         "5ZYma87uvGEYz8px4qwpMzmC"
    //     ],
    //     "roles": [
    //         "admin"
    //     ],
    //     "created_on": {
    //         "seconds": 1693096516,
    //         "nanoseconds": 927000000
    //     },
    //     "id": "64f69854879b29f8b4dd51cd"
    // }

    const [deckShowMore, setDeckShowMore] = useState(20);
    const {
        deckQuery,
        setDeckQuery,
        deckSortState,
        setDeckSortState,
    } = useContext(DeckQueryContext)

    const [loading, setLoading] = useState(false)

    const {option} = props;
    const navigate = useNavigate()
    const [optionDecks, setOptionDecks] = useState(false)

    const handleGetDecks = () => {
        if (option === "myDecks") {
            getDecks()
        }
    }

    const getDecks = async() =>{
        setLoading(true)
        const accountDeckData = await deckQueries.getQueriedDecksData({account_id: account.id});
        if (accountDeckData) {
            const sortedDecks = [...accountDeckData].sort(deckSortMethods[deckSortState].method);
            setDecks(sortedDecks.reverse())
        } else {
            setNoDecks(true)
        }
        setLoading(false)
    };

    const getRandomDeck = async() =>{
        const randomIndex = Math.floor(Math.random() * decks.length);
        const randomDeck = decks[randomIndex].id;
        navigate(`/decks/${randomDeck}`);
    }

    useEffect(() => {
        handleGetDecks()
        // getAccountData();
        document.title = "Account Info - PM CardBase"
        return () => {
            document.title = "PlayMaker CardBase"
        };
    // eslint-disable-next-line
    }, [option]);

    const deckSortMethods = {
        none: { method: (a,b) => b.id.localeCompare(a.id) },
        newest: { method: (a,b) => b.id.localeCompare(a.id) },
        oldest: { method: (a,b) => a.id.localeCompare(b.id) },
        name: { method: (a,b) => a.name.localeCompare(b.name) },
        updated: { method: (a,b) => new Date(b.updated_on.full_time) - new Date(a.updated_on.full_time) },
    };

    // const handleDeckQuery = (event) => {
    //     setDeckQuery({ ...deckQuery, [event.target.name]: event.target.value });
    // };

    // const handleDeckQueryReset = (event) => {
    //     setDeckQuery({
    //         deckName: "",
    //         description: "",
    //         cardName: "",
    //         strategy: "",
    //         seriesName: "",
    //     });
    //     setDeckSortState("none")
    // };

    // const handleDeckSort = (event) => {
    //     setDeckSortState(event.target.value);
    // };

    const handleDeckShowMore = (event) => {
        setDeckShowMore(deckShowMore + 20);
    };

    const all_decks = decks
        // decks.filter(deck => deck.name.toLowerCase().includes(deckQuery.deckName.toLowerCase()))
        // .filter(deck => (deck.description).toLowerCase().includes(deckQuery.description.toLowerCase()))
        // .filter(deck => deckQuery.cardName ? (deck.card_names && deck.card_names.length > 0 ? deck.card_names.some(name => name.toLowerCase().includes(deckQuery.cardName.toLowerCase())) : false) : true)
        // .filter(deck => deckQuery.strategy? deck.strategies.some(strategy => strategy.includes(deckQuery.strategy)):deck.strategies)
        // .filter(deck => deckQuery.seriesName ? (deck.series_names && deck.series_names.length > 0 ? deck.series_names.some(series => series.toLowerCase().includes(deckQuery.seriesName.toLowerCase())) : false) : true)
        .sort(deckSortMethods[deckSortState].method)

    // const all_decks = all_decks.filter(deck => account && deck.account_id && deck.account_id === account.id)


    return (
        <>
            { !noDecks?
                <div>
                    {option === "myDecks"?
                        <div className="account-options-container">
                            <span className="flex-left-media-center">
                                <div className="margin-bottom-10 media-margin-bottom-none">
                                    <h1 className="left-h1 margin-top-none media-margin-top-40">My Uploaded Decks</h1>
                                    <h4 className="left">Showing 1 - {all_decks.slice(0, deckShowMore).length} of {all_decks.length}</h4>
                                </div>
                            </span>
                            <br/>
                            { loading ?
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                </div> :
                            null}
                            {!loading ?
                                <div className="account-option-items account-scrollable">
                                    {all_decks.slice(0, deckShowMore).map((deck, index) => {
                                        const marginBottom = index < all_decks.length - 1 ? '15px' : '0';
                                        return (
                                            <NavLink to={`/decks/${deck.id}`}>
                                                <Card className="text-white text-center card-list-card3 glow"
                                                    style={{marginBottom}}>
                                                    <div className="card-image-wrapper">
                                                        <div className="card-image-clip3">
                                                            <Card.Img
                                                                src={deck.cover_card ? deck.cover_card : "https://i.imgur.com/8wqd1sD.png"}
                                                                alt="Card image"
                                                                className="card-image2"
                                                                variant="bottom"/>
                                                        </div>
                                                    </div>
                                                    <Card.ImgOverlay className="blackfooter2 mt-auto">
                                                    <div className="flex">
                                                        <h3 className="left cd-container-child ellipsis">{deck.name}</h3>
                                                        { deck.private && deck.private === true ?
                                                            <img className="logo4" src="https://i.imgur.com/V3uOVpD.png" alt="private" />:null
                                                        }
                                                    </div>
                                                    {/* <h6 style={{margin: '0px 0px 5px 0px', fontWeight: "600"}}
                                                    >
                                                        User:
                                                    </h6> */}
                                                    <h6 className="left ellipsis2"
                                                        style={{margin: '0px 0px 5px 10px', fontWeight: "600"}}
                                                    >
                                                        Strategies: {deck.strategies.length > 0 ? deck.strategies.join(', ') : "n/a"}
                                                    </h6>
                                                    <h6 className="left"
                                                        style={{margin: '0px 0px 10px 10px', fontWeight: "600"}}
                                                    >
                                                        Main Deck: {deck.cards.length} &nbsp; Pluck Deck: {deck.pluck.length}
                                                    </h6>
                                                    <div style={{ display: "flex", maxWidth: "90%"}}>
                                                        <img className="logo2" src="https://i.imgur.com/nIY2qSx.png" alt="created on"/>
                                                        <h6
                                                        className="left justify-content-end"
                                                            style={{margin: '5px 0px 5px 5px', fontWeight: "600", textAlign: "left"}}
                                                        >
                                                            {deck.created_on.ago} &nbsp; &nbsp;
                                                        </h6>
                                                        <img className="logo3" src="https://i.imgur.com/QLa1ciW.png" alt="updated on"/>
                                                        <h6
                                                        className="left justify-content-end ellipsis2"
                                                            style={{margin: '5px 0px 5px 5px', fontWeight: "600", textAlign: "left"}}
                                                        >
                                                            {deck.updated_on.ago} &nbsp; &nbsp;
                                                        </h6>
                                                        {/* <img className="logo2" src="https://i.imgur.com/eMGZ7ON.png" alt="created by"/>
                                                        <h6
                                                        className="left justify-content-end"
                                                            style={{margin: '5px 0px 5px 5px', fontWeight: "600", textAlign: "left"}}
                                                        >
                                                            {createdBy(deck)}
                                                        </h6> */}
                                                    </div>
                                                </Card.ImgOverlay>
                                                </Card>
                                            </NavLink>
                                        );
                                    })}
                                {deckShowMore < all_decks.length ?
                                    <button
                                    variant="dark"
                                    style={{ width: "100%", marginTop:"2%"}}
                                    onClick={handleDeckShowMore}>
                                        Show More Decks ({all_decks.length - deckShowMore} Remaining)
                                    </button> : null }
                                </div>:null
                            }
                        </div>: null
                    }
                </div>:
                <div>
                    {option === "myDecks"?
                        <div className="textwindow">
                            <h1 className="undercontext">Looks Like There's Nothing Here!</h1>
                        </div>: null
                    }
                </div>
            }
        </>
    );
}

export default AccountDecks;
