import { useState, useEffect, useContext, useRef } from "react";
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import { AuthContext } from "../context/AuthContext";

function DeckSheetPage({
    name,
    main_list,
    pluck_list
}) {

    const { account } = useContext(AuthContext)

    const watermarkUrl = process.env.PUBLIC_URL + "/watermark.jpg"
    const getDeck = async() =>{
        const imageList = []
        for (let card of main_list) {
            const imageLink = card.picture_url.replace(
                "https://compressedplaymakercards.s3.us-west-1.amazonaws.com/", process.env.PUBLIC_URL + "/cardImages/"
            )
            imageList.push(imageLink)
        }
        for (let card of pluck_list) {
            const imageLink = card.picture_url.replace(
                "https://compressedplaymakercards.s3.us-west-1.amazonaws.com/", process.env.PUBLIC_URL + "/cardImages/"
            )
            imageList.push(imageLink)
        }
        console.log(imageList)
        let sheet = []
        const sheetList = []
        for (let i = 0; i < imageList.length; i++) {
            if (sheet.length < 9) {
                sheet.push(imageList[i])
            } else {
                sheetList.push(sheet)
                sheet = []
                sheet.push(imageList[i])
            }
        }
        sheetList.push(sheet)

        const doc = new jsPDF('p', 'mm', 'a4', true, true);
        sheetList.forEach((sheet, index) => {
            if (index > 0) {
                doc.addPage();
            }
            if (account && account.roles.includes("admin")) {
                sheet.forEach((image, i) => {
                    const x = (i % 3) * 2.5 * 25.4 + 12.7; // 2.5 inches converted to mm plus half inch margin
                    const y = Math.floor(i / 3) * 3.5 * 25.4 + 6.35; // 3.5 inches converted to mm plus half inch margin
                    doc.addImage(image, 'JPEG', x, y, 2.5 * 25.4, 3.5 * 25.4, undefined, "FAST");
                });
            } else {
                sheet.forEach((image, i) => {
                    const x = (i % 3) * 2.5 * 25.4 + 12.7; // 2.5 inches converted to mm plus half inch margin
                    const y = Math.floor(i / 3) * 3.5 * 25.4 + 6.35; // 3.5 inches converted to mm plus half inch margin
                    const width = 2.5 * 25.4;
                    const height = 3.5 * 25.4;
                    doc.addImage(image, 'JPEG', x, y, width, height, undefined, "FAST");
                    doc.setGState(new doc.GState({ opacity: 0.5 })); // Set watermark opacity
                    doc.addImage(watermarkUrl, 'PNG', x, y, width, height, undefined, "FAST");
                    doc.setGState(new doc.GState({ opacity: 1 })); // Set watermark opacity
                });
            }
        });
        doc.save(`${name}.pdf`);
    };


    return (
        <>
            <button
                className="left heightNorm"
                onClick={getDeck}
                style={{marginRight: "10px", marginLeft: "0px" }}
            >PDF</button>
        </>
    );
}


export default DeckSheetPage;
